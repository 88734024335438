<template>
  <ComplexDialog
    :title="dialogTitle"
    v-model="visible"
    :max-width="1000"
    :loading="isBusy && !processingDidList"
    :close-confirmation="dirty"

    :step="step"
    :step-end="stepEnd"
    :step-next-disabled="nextStepDisabled"
    :step-back-disabled="backStepDisabled"

    @step-next="next"
    @step-back="back"
    @step-end="finish"
    :step-end-text="endLabel"
    :step-next-text="nextLabel"

    :hideStepButtons="false"
  >
    <v-window v-model="step" touchless>
      <v-window-item :value="1">
        <h1>Emergency Service Address Record</h1><br />
        <p>A customer name and address is required for emergency services. This address needs to be the location where the phone number is in use.
          This data is used by governing bodies if any emergency connected to the phone number is to occur.</p>
        <h2 v-if="ipndData != null" class="mb-2">Selected Details</h2>
        <div v-if="ipndData != null" class="mb-4">
          <IPNDDetailsDisplayComponent
            v-if="ipndData != null"
            :ipnd-data="ipndData"
          />
        </div>

        <did v-if="ipndData != null && false">
          <h4>{{ ipndData.address_string }}</h4>
          <v-row dense>
            <v-col cols="2">Label:<br />Customer Name:<br />Usage Type:</v-col>
            <v-col>{{ipndData.label}}<br />
              {{ipndData.customer_name_1}} {{ipndData.customer_name_2}}<br />
              {{ipndData.usage_string}}
            </v-col>
          </v-row>
        </did>

        <p v-if="ipndData == null"><em>No Emergency Service Address selected.</em></p>

        <div v-if="trunkData != null && selectableIpndList != null && selectableIpndList.length > 1" class="pb-2">
          <ButtonWithTooltip color="primary" text="Select Existing Emergency Services Address" @click="ipndListDialog = true"/>
        </div>

        <div  v-if="trunkData != null && selectableIpndList != null && selectableIpndList.length == 1 && ipndData != null && selectableIpndList[0].hash != ipndData.hash" class="pb-2">
          <ButtonWithTooltip color="primary" :text="'Switch to \''+selectableIpndList[0].label+'\''" @click="selectIpnd(selectableIpndList[0])"/>
        </div>

        <ButtonWithTooltip color="primary" text="Add Emergency Services Address" @click="ipndDetailsDialog = true"/>

      </v-window-item>
      <v-window-item :value="2">
        <v-row dense>
          <v-col cols="12">
            <DIDPoolSelectV2 ref="didPoolList" showFilters statusValuePerm="I" v-model="poolDidList"/>
          </v-col>
        </v-row>
      </v-window-item>
      <v-window-item :value="3">
        <v-row dense>
          <v-col cols="12">
            <span v-if="!busy && !taskProgressPercent">Review selected DIDs then click next to assign them to the {{processTypeSwitch == 'trunk' ? this.trunkData.account_type_label : processTypeSwitch}}.</span>
            <span v-if="busy">Please wait while the DIDs are added...</span>
            <span v-if="!busy && taskProgressPercent">Operation completed, please check results below.</span>
            <v-progress-linear
              rounded
              height="25"
              v-if="busy || taskProgressPercent > 0"
              class="my-4"
              :value="taskProgressPercent"
            >
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
            <v-simple-table dense fixed-header height="400">
              <thead>
              <tr>
                <th>Phone Number</th>
                <th v-if="$store.state.user.isAdmin">Vendor</th>
                <th>Status</th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="(item) in poolDidList"
                v-bind:key="item.number"
                dense
              >
                <td>{{ item.number }}</td>
                <td v-if="$store.state.user.isAdmin">
                  <span v-if="item.vendor_tag == 'auswide'">Auswide</span>
                  <span v-if="item.vendor_tag == 'net-sip'">NetSIP</span>
                  <span v-if="item.vendor_tag == 'vx-group'">VX</span>
                </td>
                <td>
                  <span v-if="!item.processingStatus">Not yet added.</span>
                  <span v-if="item.processingStatus == 'uploading'"><v-progress-circular
                    class="mr-2"
                    :width="3"
                    :size="20"
                    color="primary"
                    indeterminate
                  ></v-progress-circular> Processing Number <span v-if="item.processingIpnd">and Emergency Service Address</span></span>
                  <span v-if="item.processingStatus == 'success'"><v-icon
                    color="success"
                    class="mr-2"
                  >mdi-check-circle</v-icon
                  > Successfully Added</span>
                  <span v-if="item.processingStatus == 'failed'"><v-icon
                    color="warning"
                    class="mr-2"
                  >mdi-alert</v-icon
                  > Failed to add: {{item.processingError}}</span>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>

    <EditTrunkIPNDAddressDialog
      v-model="ipndDetailsDialog"
      v-if="trunkData != null"
      :porta-account-data="trunkData"
      :return-values="true"
      :current-ipnd-list="trunkData == null ? [] : selectableIpndList"
      @selected="selectIpnd"
    />
    <IPNDListSelectDialog v-if="trunkData != null"
                          :ipnd-list="selectableIpndList"
                          v-model="ipndListDialog"
                          @singleSelected="selectIpnd"
    />
  </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import DIDPoolSelectV2 from "../../pieces/DID/DIDPoolSelectV2.vue";
import EditTrunkIPNDAddressDialog from "../Trunk/EditTrunkIPNDAddressDialog.vue";
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip.vue";
import IPNDDetailsDisplayComponent from "../../pieces/IPND/IPNDDetailsDisplayComponent.vue";
import IPNDListSelectDialog from "../IPND/IPNDListSelectDialog.vue";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";

/**
 * NOTE!
 * The logic contained here in regards to the IPND process does not support different vendors.
 * The first step here is established generic IPND details.
 * However we have not selected the DID so we do not know which vendor we are dealing with.
 * Hence it defaults NetSip and check the address via the NetSip process.
 * So when the address is submitted with the DID we know that it is valid for NetSip.
 *
 * However if we move have multiple vendors this logic is broken.
 * I'm not sure there is a nice way to handle this.
 * I believe that after selecting DIDs we would need to validate the IPND before we process the DID.
 * So we would check all the vendors involved and then check that the IPND details we have are valid for each vendor.
 * IE we need to submit the proposed IPND detials to their validtion API (this is what NetSip provides) which may return a list of alternate addresses (or the address formatted differently) it prefers
 * and the user need to select one of these.
 * If there is a vendor that does not like the IPND details passed then we would need some method to manage that.
 * EG we might then show the list of address the vendor matches and let the user select an address.
 *
 */
export default {
  name: 'AddDIDDialogFromPool',
  mixins: [apiMixin, dialogMixin, GlobalHelperMixin],
  components: {
    IPNDListSelectDialog,
    IPNDDetailsDisplayComponent,
    ButtonWithTooltip,
    EditTrunkIPNDAddressDialog,
    ComplexDialog,
    DIDPoolSelectV2,
  },
  data: () => ({
    step: 1,
    stepEnd: 6,

    poolDidList: [],
    dirty: false,

    isValid: false,
    busy: false,
    customer: null,
    trunk: null,
    fail: false,
    // transferType: null,
    tasksComplete: 0,
    taskProgressPercent: 0,
    processingDidList: false,

    ipndData:null,
    ipndDetailsDialog:false,
    ipndListDialog:false,
  }),
  methods: {
    selectIpnd(ipndData){
      this.ipndData = ipndData;
    },
    /**
     * Reset the entire component.
     * This is good for fresh start.
     *
     * Note the DIDPoolList component is a problem.
     * I will still display the previously searched dids.
     * Even if those are not attached to a trunk.
     * However this list will refresh if the component is created again.
     * So the reset is not really useful.
     * After a successful process the user will need to close the dialog and open it again.
     */
    reset(){
      this.poolDidList = [];
      this.taskProgressPercent = 0;
      this.ipndData = null;
      this.ipndDetailsDialog = false;
      this.step = 2;
      if(this.trunkData != null){
        this.step = 1;
        if(this.selectableIpndList.length == 1){
          this.ipndData = this.selectableIpndList[0];
        }
      }

      /*
      On the first run over this process the didPoolList is not loaded so it will not appear in the ref.
      The Pagination logic within the DidPooLList will load the list of did initially.
      Problem is this list will not load
       */
      if(this.$refs.didPoolList != null){
        this.$refs.didPoolList.update( );
      }
    },

    /*
      Note at the time of writing all IPND are running off the NetSip logic.
      If this was not the case, its possible that we need to re-evaluate the IPND prior to submission.
      Because the current process to validate the IPND will check for a valid address with NetSip.
      However validation with other vendors might yield different results.
      Hence point to note is this may need updating if more vendors are added
     */
    async processDidsFromPoolv3() {
      this.busy = true;
      this.processingDidList = true;
      this.taskProgressPercent = 0;
      let processedIpnd = false;
      let processedIpndIndex = 0;
      let vnDetails = this.getVnMappedToIpndRequirement( );

      const max = this.poolDidList.length;
      let completed = 0;

      const http = this.Api.buildHttpObject();
      let url = '';
      let urlMethod = 'put';
      let expectedReturnLabel = null;
      let expectedReturnValue = null;

      // Decide which URL/method to use
      if (this.processTypeSwitch === 'customer') {
        url = `customers/${this.customerId}/dids/assign`;
      } else if (this.processTypeSwitch === 'trunk') {
        url = `trunks/${this.trunkId}/did/assign`;
        expectedReturnLabel = 'i_account';
        expectedReturnValue = this.trunkId;
      } else {
        throw Error(`Unrecognised processTypeSwitch(${this.processTypeSwitch})`);
      }

      // Helper to handle a single DID assignment
      const doAssignDid = async (index) => {
        this.poolDidList[index].processingStatus = 'uploading';
        this.poolDidList[index].processingIpnd = !processedIpnd;
        const params = {};
        if (this.processTypeSwitch === 'customer') {
          params.i_did_number = this.poolDidList[index].i_did_number;
        } else if (this.processTypeSwitch === 'trunk') {
          params.did = this.poolDidList[index].number;
          if(vnDetails[this.poolDidList[index].vn]){
            params.ipndData = this.ipndData;
          }
        }

        try {
          await this.$nextTick();
          const response = await http.request({
            method: urlMethod,
            url: url,
            data: params,
            timeout: 60000,
          });

          // If we have an expected return, verify it
          if (expectedReturnLabel != null) {
            if (response.data[expectedReturnLabel] !== expectedReturnValue) {
              this.poolDidList[index].processingStatus = 'failed';
              this.poolDidList[index].processingError =
                'Failed to process results. Unexpected return.';
            } else {
              processedIpnd = true;
              this.poolDidList[index].processingStatus = 'success';
            }
          } else {
            processedIpnd = true;
            this.poolDidList[index].processingStatus = 'success';
          }
        } catch (error) {
          this.poolDidList[index].processingStatus = 'failed';
          if (!('response' in error) || !error.response) {
            console.error(error);
            this.poolDidList[index].processingError = 'Unknown error in request';
          } else {
            this.poolDidList[index].processingError = error.response.data.description;
          }
        } finally {
          completed++;
          processedIpndIndex++;
          if (completed >= max) {
            this.taskProgressPercent = 100;
            this.busy = false;
            this.processingDidList = false;
            document.dispatchEvent(new CustomEvent('refreshRequested'));
          } else {
            this.taskProgressPercent = Math.floor((completed / max) * 100);
          }
          this.$nextTick();
        }
      };

      // 1) Process the FIRST item with `await`
      //    so IPND gets created before starting others
      if (max > 0) {
        while(!processedIpnd) {
          await this.$nextTick();
          await doAssignDid(processedIpndIndex);
        }
      }

      // 2) Now process the remaining items with concurrency limiting
      const remainingIndices = [];
      for (let i = processedIpndIndex; i < max; i++) {
        remainingIndices.push(i);
      }

      // We'll use a concurrency limit of 5 (change as needed)
      const concurrencyLimit = 10;
      await this.runWithConcurrencyLimit(remainingIndices, concurrencyLimit, doAssignDid);
    },

// ---- Below is a generic concurrency limiter utility function ----
// This function takes an array of items (e.g. your DID indices), a concurrency limit,
// and a worker function (doAssignDid) that returns a Promise.
// It ensures we never have more than `limit` promises running at once.
    async runWithConcurrencyLimit(items, limit, worker) {
      return new Promise((resolve, reject) => {
        let runningCount = 0;     // how many tasks are currently running
        let currentIndex = 0;     // next item to start
        const total = items.length;

        function launchNext() {
          // Launch tasks until we hit the concurrency limit or no items remain
          while (runningCount < limit && currentIndex < total) {
            const item = items[currentIndex];
            currentIndex++;
            runningCount++;

            // Start the worker Promise
            worker(item)
              .then(() => {
                runningCount--;
                if (currentIndex < total) {
                  // We still have more items; launch next immediately
                  launchNext();
                } else if (runningCount === 0) {
                  // No more items and none are running -> done
                  resolve();
                }
              })
              .catch((err) => {
                // Even if there's an error, we want to keep going
                runningCount--;
                if (currentIndex < total) {
                  launchNext();
                } else if (runningCount === 0) {
                  resolve(); // or reject if you prefer to stop on error
                }
              });
          }
        }

        // Kick off the initial tasks
        launchNext();
      });
    },

    /*
      Note at the time of writing all IPND are running off the NetSip logic.
      If this was not the case, its possible that we need to re-evaluate the IPND prior to submission.
      Because the current process to validate the IPND will check for a valid address with NetSip.
      However validation with other vendors might yield different results.
      Hence point to note is this may need updating if more vendors are added
     */
    // async processDidsFromPool()
    // {
    //   this.busy = true;
    //   this.processingDidList = true;
    //   this.taskProgressPercent = 0;
    //   let max = this.poolDidList.length;
    //   let completed = 0;
    //   let params = {};
    //   let http = this.Api.buildHttpObject( );
    //   let response = null;
    //   let url = null;
    //   let urlMethod = null;
    //   let expectedReturnLabel = null;
    //   let expectedReturnValue = null;
    //   if(this.processTypeSwitch == 'customer'){
    //     url = 'customers/'+this.customerId + '/dids/assign';
    //     urlMethod = 'put';
    //   }else if(this.processTypeSwitch == 'trunk'){
    //     url = 'trunks/'+this.trunkId + '/did/assign';
    //     urlMethod = 'put';
    //     expectedReturnLabel = 'i_account';
    //     expectedReturnValue = this.trunkId;
    //   }else{
    //     throw Error('Unrecognised processTypeSwitch('+this.processTypeSwitch+')');
    //   }
    //   for(let i=0; i<max; i++) {
    //     if(max > 1 && i == 0){
    //       /*
    //         processing the first request must complete before the others.
    //         This is because of the IPND logic. The IPND needs to be created first before the other assign process can run.
    //         Else we run into race conditions problems with the unique name.
    //         EG it tries to create the same record and it fails due to a unique name issue.
    //         A database lock is too heavy for this process.
    //         Because each set of DIDs assigned an IPND we are able to process 1 of the DID to make sure the IPND portion
    //         of the logic is catered for.
    //         EG the first DID is assign and part of the process is to create the IPND.
    //         Then all other DID can be assigned concurrently as the IPND already exists.
    //        */
    //       //Note this code is a cut and past of request before except it using 'await'.
    //
    //       this.poolDidList[i].processingStatus = 'uploading';
    //       params = {};
    //       if(this.processTypeSwitch == 'customer'){
    //         params.i_did_number = this.poolDidList[i].i_did_number;
    //       }else if(this.processTypeSwitch == 'trunk'){
    //         params.did = this.poolDidList[i].number;
    //         params.ipndData = this.ipndData;
    //       }else{
    //         throw Error('Unrecognised processTypeSwitch('+this.processTypeSwitch+')');
    //       }
    //
    //
    //       response = await http.request({
    //         method: urlMethod,
    //         url: url,
    //         data: params,
    //         timeout:60000
    //       }).then((response) => {
    //         if(expectedReturnLabel != null){
    //           if(response.data[expectedReturnLabel] != expectedReturnValue){
    //             this.poolDidList[i].processingStatus = 'failed';
    //             this.poolDidList[i].processingError = 'Failed to process results. Unexpected return.';
    //           }else{
    //             this.poolDidList[i].processingStatus = 'success';
    //           }
    //         }else{
    //           this.poolDidList[i].processingStatus = 'success';
    //         }
    //       }).catch((error) => {
    //         this.poolDidList[i].processingStatus = 'failed';
    //         this.poolDidList[i].processingError = error.response.data.description;
    //       })
    //         .finally(() => {
    //           completed++;
    //           if(completed >= max){
    //             this.taskProgressPercent = 100;
    //             this.busy = false;
    //             document.dispatchEvent(new CustomEvent('refreshRequested'));
    //           }else{
    //             this.taskProgressPercent = Math.floor(((completed) / max) * 100);
    //           }
    //           this.$nextTick();
    //         });
    //
    //       // we need to advance the counter
    //       i++;
    //     }
    //
    //     this.poolDidList[i].processingStatus = 'uploading';
    //     params = {};
    //     if(this.processTypeSwitch == 'customer'){
    //       params.i_did_number = this.poolDidList[i].i_did_number;
    //     }else if(this.processTypeSwitch == 'trunk'){
    //       params.did = this.poolDidList[i].number;
    //       params.ipndData = this.ipndData;
    //     }else{
    //       throw Error('Unrecognised processTypeSwitch('+this.processTypeSwitch+')');
    //     }
    //
    //     response = http.request({
    //       method: urlMethod,
    //       url: url,
    //       data: params,
    //       timeout:60000
    //     }).then((response) => {
    //       if(expectedReturnLabel != null){
    //         if(response.data[expectedReturnLabel] != expectedReturnValue){
    //           this.poolDidList[i].processingStatus = 'failed';
    //           this.poolDidList[i].processingError = 'Failed to process results. Unexpected return.';
    //         }else{
    //           this.poolDidList[i].processingStatus = 'success';
    //         }
    //       }else{
    //         this.poolDidList[i].processingStatus = 'success';
    //       }
    //     }).catch((error) => {
    //       this.poolDidList[i].processingStatus = 'failed';
    //       if (!('response' in error)) {
    //         console.log(error);
    //       }else if (error.response == null) {
    //         console.log(error);
    //       }else{
    //         this.poolDidList[i].processingError = error.response.data.description;
    //       }
    //     }).finally(() => {
    //       completed++;
    //       if(completed >= max){
    //         this.taskProgressPercent = 100;
    //         this.processingDidList = false;
    //         this.busy = false;
    //         document.dispatchEvent(new CustomEvent('refreshRequested'));
    //       }else{
    //         this.taskProgressPercent = Math.floor(((completed) / max) * 100);
    //       }
    //       this.$nextTick();
    //     });
    //   }
    // },

    next: function( ) {
      if(this.step == 3){
        if(this.taskProgressPercent > 0){
          //At this point we are on step 3, the did are processed and the user can see a list of the processing dids.
          //We remain on this page as the user can see the DID loading and then can see a success or error for each did.
          //If the taskProgressPercent > 0 we assume the process is complete.
          //Note that during the process isBusy will be true and no buttons are active.
          //So the button can only be triggered after progress has finished (EG each did was processed).
          //Now we want to reset the dialog so the user can add more did if they wish.
          this.reset( );

        }else {
          //we now process the DID
          // this.processDidsFromPool();
          this.processDidsFromPoolv3();
        }
      }else {
        this.step++;
      }
    },
    back: function( ) {
      this.step--;
    },
    finish: function( ) {

    },
  },
  watch: {
    // transferType: function (val) {
    //     if (val !== 1) {
    //         this.carrier = null;
    //         this.accountNumber = null;
    //     }
    //     if (val !== 2) {
    //         this.migrationType = null;
    //     }
    //     this.editCustomerDetail = null;
    // },
    visible(value) {
      if(value){
        this.reset( );
      }
    },

    trunkId: function(val){
      this.reset();
    }
  },
  props: {
    title: {
      type: String,
      default: 'Add DIDs',
    },
    customerId: {
      default: null,
    },
    trunkData: {
      default: null,
    },
    loading:{
      type: Boolean,
      default: false,
    },
    /*
      used to trigger how the dialog will process DID
      'customer' = create and/or assign DIDs to the customer
      'trunk'=>create and/or assign DIDs to the trunk.
      'pool' => create DIDs into the pool.
     */
    processTypeSwitch:{
      type: String,
      required: true,
    },
  },
  computed: {
    isBusy: function( ){
      return this.loading || this.busy;
    },
    trunkId: function( ){
      if(this.trunkData == null){
        return null;
      }else{
        return this.trunkData.i_account;
      }
    },
    dialogTitle: function(){
      return this.title + ': Step ' + this.step;
    },
    nextStepDisabled: function( ) {
      if(this.isBusy){
        return true;
      }
      if(this.step == 1 && this.ipndData == null){
        return true;
      }
      if(this.step == 2 && this.poolDidList.length==0){
        return true;
      }
      if(this.step == 3){
        //Note that we do not need check the taskProgressPercent
        //During processing the isBusy toggle will be true
        return false;
      }
      return false;
    },
    backStepDisabled: function( ) {
      if(this.isBusy){
        return true;
      }
      if(this.taskProgressPercent != 0){
        //if the taskProcessPercent is not zero we are in the presence of processing, processing is complete or there was a bug.
        return true;
      }

      if(this.step == 2 && this.trunkData == null){
        //step one is for emergency address. Not always required.
        return true;
      }
      return false;
    },
    nextLabel: function( ){
      if(this.step == 3){
        if(this.taskProgressPercent == 0){
          if(this.poolDidList.length !== 1){
            return 'Add '+this.poolDidList.length+' DIDs';
          }else{
            return 'Add DID';
          }
        }else{
          return 'Finish';
        }
      }else{
        return 'Next';
      }
    },
    endLabel: function( ){
      return 'Close';
    },
    selectableIpndList: function( ){
      if(this.trunkData == null || this.trunkData.ipnd_list == null){
        return [];
      }
      let ret = [];
      this.trunkData.ipnd_list.forEach(function (item) {
        if(!item.is_bc_address && item.vendor_deleted_at == null && !item.pending_clean_up){
          ret.push(item);
        }
      });

      return ret;
    }
  },
  // created( ){
  //   this.reset( );
  // }
}
</script>
