<template>
  <div>
    <v-card v-if="showFilters" class="pa-2 mb-2">
      <v-row>
        <v-col cols="12" :sm="showCreateButton ? 3 : 4">
          <v-text-field
            hide-details
            outlined
            clearable
            dense
            label="Auth ID"
            :disabled="isLoading"
            v-model="localFilter.id"
            @keydown.enter="updateLocalFilter"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            hide-details
            outlined
            clearable
            dense
            label="Description"
            :disabled="isLoading"
            v-model="localFilter.note"
            @keydown.enter="updateLocalFilter"
          />
        </v-col>
        <v-col cols="12" :sm="showCreateButton ? 2 : 3">
          <v-select
            hide-details
            outlined
            dense
            label="Status"
            v-model="localFilter.active"
            :disabled="isLoading"
            :items="statusOptions"
            item-text="option"
            item-value="value"
          />
        </v-col>
        <v-col cols="6" sm="2">
          <v-btn
            outlined
            block
            height="40"
            :loading="isLoading"
            @click="updateLocalFilter"
          >Search</v-btn>
        </v-col>
        <v-col cols="6" sm="2" v-if="showCreateButton">
          <v-btn
            outlined
            block
            height="40"
            :loading="isLoading"
            @click="emitCreate"
            class="primary"
            :dark="!$vuetify.theme.dark"
          ><span classss="hidden-md-and-down" class="hidden-md-only"><i class="v-icon mdi mdi-plus"></i> Create</span>
            <span classss="hidden-lg-and-up" class="d-none d-md-flex d-lg-none d-xl-none"><i class="v-icon mdi mdi-plus"></i></span></v-btn>
        </v-col>
      </v-row>
    </v-card>
    <PaginationCard
      :page="filter.page"
      :data="data"
      :limit="filter.limit"
      :loading="loading || isLoading"
      v-on:updateLimit="updateLimit"
      v-on:updatePage="updatePage"
      :no-skeleton="!firstLoad"
    >
      <v-simple-table>
        <thead>
        <tr>
          <th>Auth ID</th>
          <th v-if="!small" class="text-left">
            Description
          </th>
          <th v-if="!custId">Customer</th>
          <th v-if="!small" class="text-left">Product</th>
          <th class="text-center">Status</th>
        </tr>
        </thead>

        <v-slide-x-transition tag="tbody" hide-on-leave group>
          <tr v-if="data.data && data.data.length === 0" key="nodata">
            <td colspan="100%" class="text-center">
              No Trunks found
            </td>
          </tr>
          <tr
            v-for="item in data.data"
            :key="item.id"
            v-bind:class="{
                            'red darken-4':
                                $vuetify.theme.dark && item.bill_status !== 'C' && item.blocked === 'Y',
                            'red lighten-4':
                                !$vuetify.theme.dark && item.bill_status !== 'C' && item.blocked === 'Y',
                            'grey darken-3':
                                $vuetify.theme.dark && item.bill_status === 'C',
                            'grey lighten-3':
                                !$vuetify.theme.dark && item.bill_status === 'C',
                        }"
          >
            <td>
              <router-link
                :to="{
                                    name: 'TrunkSummary',
                                    params: {
                                        customerId: item.i_customer,
                                        trunkId: item.i_account,
                                    },
                                }"
                class="font-weight-medium"
              >{{ item.id }}</router-link
              >
            </td>
            <td v-if="!small">
              {{ item.note }}
            </td>
            <td v-if="!custId">
              <router-link
                :to="{
                                    name: 'CustomerSummary',
                                    params: {
                                        customerId: item.i_customer,
                                    },
                                }"
              >{{ item.customer_name || item.i_customer }}</router-link
              >
            </td>
            <td v-if="!small">
              {{ item.product_visible_name }}
            </td>
            <td class="text-center">
              <TrunkStatus :data="item" />
            </td>
          </tr>
        </v-slide-x-transition>
      </v-simple-table>
    </PaginationCard>
  </div>
</template>

<script>
import PaginationCard from '@/components/cards/templates/PaginationCard';
import TrunkStatus from '../../pieces/Trunk/TrunkStatus';
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import apiMixin from '../../../mixins/apiMixin';
import paginationComponentMixin from '../../../mixins/paginationComponentMixin';
export default {
  name: 'TrunkListCard',
  mixins: [apiMixin, paginationComponentMixin, refreshListenerMixin],
  data: () => ({
    localFilter: {
      id: null,
      active: 'Y',
      note:null,
    },
    statusOptions: [
      { option: 'Active', value: 'Y' },
      { option: 'Terminated', value: 'N' },
    ],
  }),
  components: {
    TrunkStatus,
    PaginationCard,
  },
  props: {
    hidePagination: {
      type: Boolean,
      default: false,
    },
    repId: {
      type: Number,
      default: null,
    },
    custId: {
      type: Number,
      default: null,
    },
    showAddress: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    showCreateButton: {
      type:Boolean,
      default: false
    }
  },
  methods: {
    async update() {
      this.isLoading = true;
      this.getLocalFilters();
      let params = { limit: this.filter.limit, page: this.filter.page, trunkType:'sip-trunk'};
      if (this.custId > 0) {
        params.i_customer = this.custId;
      } else {
        params.get_customer_names = 'Y';
      }
      if (this.repId > 0)
        params.i_rep = this.repId;
      if (this.filter.active !== null)
        params.active = this.filter.active;
      if (this.filter.id)
        params.id = this.filter.id;
      if (this.filter.note)
        params.note = this.filter.note;

      params.fast = 'Y';

      let queryString = Object.keys(params).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
      }).join('&');

      const response = await this.Api.send('get','trunks?'+queryString);
      this.isLoading = false;
      if (response.success) {
        this.data = response.data;
        if (this.firstLoad) this.firstLoad = false;
      } else {
        this.data = [];
      }
    },

    emitCreate( ) {
      this.$emit('createNew')
    }
  },
};
</script>
