<template>
  <v-row dense>
    <v-col cols="12" sm="6" lg="4">
      <TrunkCard :data="data" :loading="loading" />
    </v-col>
    <v-col cols="12" sm="6" lg="4" v-if="false">
      <AddressCard :data="data" :loading="loading || data.i_account == null" @changeAddress="changeAddress()"/>
    </v-col>
    <v-expand-transition>
      <v-col cols="12" sm="6" lg="4" v-if="data.account_type == 'RING-GROUP'">
        <RingGroupSettingsCard :data="data" :loading="loading" />
      </v-col>
    </v-expand-transition>
    <v-col cols="12" sm="6" lg="4">
      <TrunkProductsCard :data="data" :loading="loading"/>
    </v-col>
    <v-expand-transition>
      <v-col cols="12" sm="6" lg="4"  v-if="showAuth" >
        <TrunkAuthCard :data="data" :loading="loading" />
      </v-col>
    </v-expand-transition>
    <v-expand-transition>
      <v-col cols="12" sm="6" lg="4" v-if="showRegistration" >
        <TrunkRegistrationCard
          :data="data"
          :loading="loading"
          v-if="data.bill_status !== 'C'"
        />
      </v-col>
    </v-expand-transition>
    <v-expand-transition>
      <v-col cols="12" sm="6" lg="4" v-if="data.account_type == 'HOSTED-EXTENSION'">
        <ExtensionContactDetailsCard :data="data" :loading="loading" />
      </v-col>
    </v-expand-transition>
    <v-expand-transition>
      <v-col cols="12" sm="6" lg="4" v-if="data.account_type == 'FAX-TO-MAIL'">
        <TrunkFax2MailCard :data="data" :loading="loading"></TrunkFax2MailCard>
      </v-col>
    </v-expand-transition>
    <v-expand-transition>
      <v-col cols="12" sm="6" lg="4" v-if="data.ipnd_list && data.ipnd_list.length > 0 && data.show_ipnd_card">
        <IPNDCard :card-type="'trunk'" :porta-account-data="data" :ipnd-data="data.ipnd_list[0]" :loading="loading" v-if="data.ipnd_list.length == 1"/>
        <TrunkIPNDMultipleCard :porta-account-data="data" :loading="loading" v-if="validIpnds.length > 1"/>
      </v-col>
    </v-expand-transition>
    <UpdateAddressDialog v-model="changeAddressDialog" :trunkId="data.i_account" :customerId="data.i_customer" typeSwitch="trunk" :emitOnChange="data && data.ipnd_details && data.ipnd_details.type == 'single'" @addressSelected="addressSelect( )"/>
    <EditIPNDAddressDialog
      v-if="data.ipnd_details"
      v-model="changeIPNDAddressDialog"
      :porta-account-data="data"
      typeTag="account"
    ></EditIPNDAddressDialog>
  </v-row>
</template>

<script>
import TrunkCard from "../../components/cards/Trunk/TrunkCard.vue";
import AddressCard from "../../components/cards/AddressCard.vue";
import TrunkRegistrationCard from "../../components/cards/Trunk/TrunkRegistrationCard.vue";
import TrunkAuthCard from '../../components/cards/Trunk/TrunkAuthCard';
import TrunkProductsCard from '../../components/cards/Trunk/TrunkProductsCard';
import UpdateAddressDialog from '../../components/dialogs/Addresses/UpdateAddressDialog'
import ExtensionContactDetailsCard from "../../components/cards/Extension/ExtensionContactDetailsCard";
import TrunkFax2MailCard from "../../components/cards/Trunk/TrunkFax2MailCard";
import RingGroupSettingsCard from "../../components/cards/RingGroup/RingGroupSettingsCard";
import GlobalFetchStoreMixin from "../../mixins/GlobalFetchStoreMixin";
import apiMixin from "../../mixins/apiMixin";
import refreshListenerMixin from "../../mixins/refreshListenerMixin";
import IPNDCard from "../../components/cards/IPND/IPNDCard.vue";
import TrunkIPNDMultipleCard from "../../components/cards/Trunk/TrunkIPNDMultipleCard.vue";
import EditIPNDAddressDialog from "../../components/dialogs/NetSip/SelectIPNDAddressDialog.vue";
export default {
  name: 'TrunkSummary',
  mixins: [GlobalFetchStoreMixin],
  data: () => ({
    busy:false,
    dialogs: {
      newDID: false,
    },
    changeAddressDialog:false,
    changeIPNDAddressDialog:false,
    extensionRingGroupsResponse:null
  }),
  components: {

    EditIPNDAddressDialog,
    RingGroupSettingsCard,
    UpdateAddressDialog,
    TrunkProductsCard,
    TrunkAuthCard,
    TrunkRegistrationCard,
    AddressCard,
    TrunkCard,
    ExtensionContactDetailsCard,
    TrunkFax2MailCard,
    IPNDCard,
    TrunkIPNDMultipleCard
  },
  computed: {
    data() {
      if (
        this.$store.state.data.trunk &&
        this.$store.state.data.trunk.i_account ===
        parseInt(this.$route.params.trunkId)
      ) {
        return this.$store.state.data.trunk;
      }
      return {};
    },
    loading() {
      return this.fetchStoreLoading || this.busy;
    },
    isHostedExtension( ){
      return this.data.account_type == 'HOSTED-EXTENSION';
    },
    showAuth( ){
      if(this.data.account_type == null){
        return false;
      }else if(this.data.account_type == 'FAX-TO-MAIL' || this.data.account_type == 'RING-GROUP' || this.data.account_type == 'AUTO-ATTENDANT'){
        return false;
      }else {
        return true;
      }
    },
    showRegistration( ){
      if(this.data.i_account_role !== 1){
        return false;
      }else if(this.data.account_type == 'FAX-TO-MAIL' || this.data.account_type == 'RING-GROUP' || this.data.account_type == 'AUTO-ATTENDANT'){
        return false;
      }else {
        return true;
      }
    },
    validIpnds( ){
      if(this.data == null || this.data.ipnd_list == null){
        return [];
      }
      let ret = [];
      this.data.ipnd_list.forEach(function (item, i) {
        if(!item.is_bc_address && item.vendor_deleted_at == null && !item.pending_clean_up){
          ret.push(item);
        }
      });
      return ret;
    }
  },

  methods: {
    changeAddress( ) {
      if(this.data.ipnd_details.type == 'single'){
        this.changeIPNDAddressDialog = true;
      }else {
        this.changeAddressDialog = true;
      }
    },
    addressSelect( ){

    }
  },

};
</script>
