<template>
  <ComplexDialog
      title="Edit Contact Details"
      v-model="visible"
      :loading="busy"
  >
    <v-form ref="form" v-model="isValid">
      <v-text-field v-model="form.firstname" label="First Name" outlined clearable :rules="rules.firstName"/>
      <v-text-field v-model="form.lastname" label="Last Name" outlined clearable :rules="rules.lastName"/>
      <v-text-field v-model="form.email" label="Email Address" outlined clearable :rules="rules.email"/>
      <v-text-field v-model="form.phone1" label="Contact Phone/Mobile" outlined clearable :rules="rules.phone1"/>
    </v-form>
    <template v-slot:actions>
      <v-spacer />
      <v-btn
          :loading="busy"
          @click="update"
          text
          :disabled="!isValid"
      >Save</v-btn>
    </template>
  </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import trunkSettingsDataMixin from '@/mixins/trunkSettingsDataMixin';
export default {
  name: 'EditExtensionContactDialog',
  mixins: [apiMixin, dialogMixin, trunkSettingsDataMixin],
  components: { ComplexDialog },
  data: () => ({
    valid: false,
    form: {
      firstName:null,
      lastName:null,
      email:null,
      phone1:null,
    },
    busy: false,
    isValid: false,
    rules: {
      firstName: [
        (v) => !!v || 'Required',
        (v) => (!!v && v.length > 1) || 'First name must contain at least 2 characters.'
      ],
      lastName: [
        (v) => !!v || 'Required',
        (v) => (!!v && v.length > 1) || 'Last name must contain at least 2 characters.'
      ],
      email: [
        (v) => !!v || 'Required',
        (v) => /^.+@.+\..+$/.test(v) || 'Invalid email',
      ],
      phone1: [
        (v) => !!v || 'Required',
        (v) => (/^(?:0[2,3,5-9]\d{8})?$/.test(v) || /^(?:04\d{8})?$/.test(v)) || 'Invalid Phone/Mobile number.'
      ],
    }

  }),
  methods: {
    async update() {
      this.busy = true;
      const response = await this.Api.send('put', 'extensions/' + this.data.extensionDetails.i_c_ext, this.form);
      if (response.success) {
        document.dispatchEvent(new CustomEvent('refreshRequested'));
        this.visible = false;
      }
      this.busy = false;
    },

  },

  props: {
    data: {
      type: Object,
      default: null
    }
  },
  created(){
    this.form.firstname = this.data.firstname;
    this.form.lastname = this.data.lastname;
    this.form.email = this.data.email;
    this.form.phone1 = this.data.phone1;
  }
};
</script>