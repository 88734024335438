<template>
  <v-row dense v-if="customerData">
    <v-col cols="12">
      <TrunkListCard
        :cust-id="customerData.i_customer"
        :loading="customerLoading"
        @update="updateFilter"
        :filter="filter"
        show-filters
        show-create-button
        @createNew="showNewTrunk=true"
      />
    </v-col>
    <FloatingButton v-if="customerData && customerData.status != 'closed'" tooltip="Add New Trunk" icon="mdi-plus" @click="showNewTrunk=true" :loading="customerLoading" />
    <NewTrunkDialog v-model="showNewTrunk" v-if="customerData.i_customer" :customer-id="customerData.i_customer" :customer-data="customerData" @trunk="goToTrunk" />
  </v-row>
</template>

<script>
import TrunkListCard from '../../components/cards/Trunk/TrunkListCard';
import filterMixin from '../../mixins/filterMixin';
import customerDataMixin from '../../mixins/customerDataMixin';
import FloatingButton from '@/components/pieces/FloatingButton';
import NewTrunkDialog from "../../components/dialogs/Trunk/NewTrunkDialog.vue";
import store from "../../store";
export default {
  name: 'CustomerTrunkList',
  mixins: [filterMixin, customerDataMixin],
  components: {
    NewTrunkDialog,
    FloatingButton,
    TrunkListCard,
  },
  methods: {
    goToTrunk(id) {
      this.showNewTrunk = false;
      this.$router.push({ name: 'TrunkSummary', params: { customerId: this.customerData.i_customer, trunkId: id }});
    }
  },
  computed: {
    customerTypeTag: function () {
      if(this.customerData != undefined){
        return this.customerData.customer_class_tag;
      }
      return undefined;
    }
  },
  created(){
    if(this.customerTypeTag != undefined && this.customerTypeTag != 'sip-trunk'){
      this.$router.push({name: 'CustomerSummary'});
    }
  },
  watch: {
    customerTypeTag(value) {
      if(value != undefined && value != 'sip-trunk'){
        this.$router.push({name: 'CustomerSummary'});
      }
    },
    business: {
      handler(data) {
        this.contact = (data && data.contact) ? data.contact : null
      },
      deep: true
    },
    personal: {
      handler(data) {
        this.contact = (data && data.contact) ? data.contact : null
      },
      deep: true
    },
  },
  data: () => ({
    filter: {
      limit: 10,
      page: 1,
      id: null,
      note:null,
      active: 'Y'
    },
    showNewTrunk: false
  })
};
</script>
