<template>
  <ComplexDialog
    v-model="visible"
    :title = "title"
    :max-width="1000"
    :step="1"
    :stepEnd="1"
    :stepEndText="'Next'"
    :stepEndDisabled="isBusy || addType == null"
    @step-end="typeSelected"
  >
    <p class="subtitle-1 text--primary">
      What type of DID request is this?
    </p>
    <RadioSelect
      v-model="addType"
      :items="radioOptions"
    />
  </ComplexDialog>
</template>

<script>
/**
 * This used for the initial type selection of adding in a Dialog.
 * At the time of writing Admin can select all 3 and other users only have access to 1 type.
 * So we separate this out.
 */

import RadioSelect from '../../pieces/Forms/RadioSelect';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import ComplexDialog from "../templates/ComplexDialog.vue";
export default {
  name: 'AddDIDDialogTypeSelect',
  mixins: [apiMixin, dialogMixin],
  components: {
    ComplexDialog,
    RadioSelect,
  },
  data: () => ({
    busy: false,
    addType:null,

  }),
  methods: {
    typeSelected: function( ){
      this.$emit('selected',this.addType);
      this.visible = false;
    }
  },
  props: {
    optionSearchPool:{
      type: Boolean,
      default: true,
    },
    optionFromCarrier:{
      type: Boolean,
      default: true,
    },
    optionManualEntry:{
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'Add DIDs',
    },
    loading:{
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isBusy: function() {
      return this.loading || this.busy;
    },
    radioOptions: function() {
      let ret = [];

      if (this.optionSearchPool) {
        ret.push({
          value: 1,
          label: 'Search pool',
          description:
            'Search available DIDs that already exist in the pool',
          disabled: false,
        });
      }

      // This method has been around for a while but does nothing. Commented out for now.
      // if (this.optionFromCarrier) {
      //   ret.push({
      //     value: 2,
      //     label: 'Order from carrier',
      //     description: 'Order new DIDs from upstream carrier',
      //     disabled: true,
      //   });
      // }

      if (this.optionManualEntry) {
        ret.push({
          value: 3,
          label: 'Create (manual entry)',
          description:
            'Manually enter numbers to be created in the DID inventory',
        });
      }
      return ret;
    }
  }
}
</script>