<template>
    <BasicCard
        title="Emergency Services Addresses (IPND)"
        v-bind:class="{
            'error lighten-4': (!$vuetify.theme.dark && cardErrorStyle),
            'error darken-4': ($vuetify.theme.dark && cardErrorStyle),
        }"
        :loading="isBusy"
    >
      <template v-slot:feature>
        <v-icon x-large color="error" v-if="cardErrorStyle">mdi-alert-circle</v-icon>
      </template>

      <Alert
        v-if="hasUnconfirmed"
        type="error"
        message="Contains unconfirmed Address(es). Manage these addresses to continue."
      />

      <Alert
        v-if="hasBCAddress"
        type="error"
        message="Did(s) found without an Emergency Service Address. Manage Dids to fix."
      />
      <ul>
        <li v-for="ipnd in previewAddresses"
            :key="ipnd.ipnd_id">{{ipnd.label}}:<br /><strong>{{ipnd.address_string}}</strong></li>
      </ul>
      <p v-if="validIpnds.length > addressDisplayLimit">
        + {{validIpnds.length - addressDisplayLimit}} more
      </p>

        <template v-slot:actions >
          <ButtonWithTooltip
            text="Manage Addresses"
            icon="mdi-pencil"
            flat
            v-if="validIpnds.length > 1"
            :to="{name: 'TrunkIPNDList', params: {trunkId: portaAccountData.i_account}}"
            IPNDList
          />
        </template>
      </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard.vue';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip.vue';
import Alert from "../../pieces/Alert.vue";
import LocalDateTime from '../../pieces/LocalDateTime.vue';
import apiMixin from '../../../mixins/apiMixin';
import SimpleDialog from "../../dialogs/templates/SimpleDialog.vue";
import EditTrunkIPNDAddressDialog from "../../dialogs/Trunk/EditTrunkIPNDAddressDialog.vue";
export default {
    name: 'TrunkIPNDMultipleCard',
    components: { ButtonWithTooltip,BasicCard,Alert},
    //mixins: [apiMixin],
    data: () => ({
        busy: false,
        editIPNDAddressDialog: false,
        addressDisplayLimit:3,
    }),
    computed: {
        isBusy: function () {
          return this.busy || this.loading || this.portaAccountData==null;
        },
        cardErrorStyle: function (){
          return this.hasUnconfirmed || this.hasBCAddress;
        },
        hasUnconfirmed: function (){
          let found = false;
          this.portaAccountData.ipnd_list.forEach(function (item) {
            if(!item.is_bc_address && !item.confirmed){
              found = true;
            }
          });

          return found;
        },

        hasBCAddress: function (){
          let found = false;
          this.validIpnds.forEach(function (item) {
            if(item.is_bc_address){
              found = true;
            }
          });

          return found;
        },
          /*
          This returns some address to preview. Subset and ignored BC addresses
           */
        previewAddresses: function( ){
          let found = [];
          for (let item of this.validIpnds) {
            if (!item.is_bc_address) {
              found.push(item);
            }
            if (found.length > this.addressDisplayLimit) {
              break;  // Now 'break' works because it's inside a for loop
            }
          }
          return found;
        },
      validIpnds( ){
        if(this.portaAccountData == null || this.portaAccountData.ipnd_list == null){
          return [];
        }
        let ret = [];
        this.portaAccountData.ipnd_list.forEach(function (item, i) {
          if(!item.is_bc_address && item.vendor_deleted_at == null && !item.pending_clean_up){
            ret.push(item);
          }
        });
        return ret;
      }
    },
    props: {
      portaAccountData: {
        type: Object,
        default: null,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      reset() {
          this.editConfirmedAddressDialog = false;
          this.editUnconfirmedAddressDialog = false;
      },
        // async update() {
        //     this.isLoading = true;
        //     const response = await this.Api.send('post', 'tickets/myagentinfo', null, true);
        //     this.isLoading = false;
        //     if (response.success) {
        //         this.data = response.data.data;
        //     } else {
        //         this.data = [];
        //     }
        // },
    },
    created( ){
      this.reset( );
    },
};
</script>
