<template>
  <ComplexDialog
    :title="dialogTitle"
    v-model="visible"
    :max-width="1200"
    :loading="isBusy"
    :close-confirmation="dirty"

    :step="step"
    :step-end="stepEnd"
    :step-next-disabled="nextStepDisabled"
    :step-back-disabled="backStepDisabled"

    @step-next="next"
    @step-back="back"
    @step-end="finish"
    :step-end-text="endLabel"
    :step-next-text="nextLabel"

    :hideStepButtons="false"
  >
    <v-window v-model="step" touchless>
      <v-window-item :value="1">
        <v-row dense>
          <v-col cols="12">
            <DIDListInput
              ref="refDidListInput"
              show-vendor
              validate-numbers
              show-carrier-status
              @update="updateDidList"
              :trunk-data="processTypeSwitch=='trunk' ? trunkData : null"
            />
          </v-col>
        </v-row>
      </v-window-item>
      <v-window-item :value="2">
        <v-row dense>
          <v-col cols="12">
            <p class="subtitle-1 text--primary">
                            <span v-if="!busy && !taskProgressPercent"
                            >Please review the list of
                                {{ didList.length }} DID<span
                                v-if="didList.length !== 1"
                              >s</span
                              > to be added</span>
              <span v-if="busy">Please wait while the DIDs are added...</span>
              <span v-if="!busy && taskProgressPercent">Operation completed, please check results below.</span>
            </p>
            <v-progress-linear
              rounded
              height="25"
              v-if="busy || taskProgressPercent > 0"
              class="my-4"
              :value="taskProgressPercent"
            >
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
            <v-simple-table dense fixed-header height="400">
              <thead>
              <tr>
                <th>Phone Number</th>
                <th>Vendor</th>
                <th>Status</th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="item in didList"
                v-bind:key="item.number"
                dense
              >
                <td>{{ item.number }}</td>
                <td>
                  {{vnToIndex[item.i_vendor]}}
                </td>
                <td>
                                        <span v-if="!busy && !taskProgressPercent">Add DID to inventory<span v-if="trunkId">and assign to trunk</span>
                                          <span v-if="customerId && !trunkId">
                                                and assign to customer</span>
                                        </span>
                  <span v-if="busy || taskProgressPercent">
                                            <v-icon
                                              color="success"
                                              class="mr-2"
                                              v-if="item.success"
                                            >mdi-check-circle</v-icon
                                            >
                                            <v-icon
                                              color="warning"
                                              class="mr-2"
                                              v-if="item.error"
                                            >mdi-alert</v-icon
                                            >
                                            <v-progress-circular
                                              v-if="item.busy"
                                              class="mr-2"
                                              :width="3"
                                              :size="20"
                                              color="primary"
                                              indeterminate
                                            ></v-progress-circular
                                            >{{ item.status }}
                                        </span>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-window-item>
      <v-window-item :value="3">
        <v-row dense>
          <v-col cols="12">
            <p class="subtitle-1 text--primary">
              <span v-if="!busy && !taskProgressPercent">Please review the list of {{ didList.length }} DID<span v-if="didList.length !== 1">s</span> to be added</span>
              <span v-if="busy">Please wait while the DIDs are added...</span>
              <span v-if="!busy && taskProgressPercent">Operation completed, please check results below.</span>
            </p>
            <v-progress-linear
              rounded
              height="25"
              v-if="busy || taskProgressPercent > 0"
              class="my-4"
              :value="taskProgressPercent"
            >
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
            <v-simple-table dense fixed-header height="400">
              <thead>
              <tr>
                <th>Phone Number</th>
                <th>Vendor</th>
                <th v-if="processTypeSwitch=='trunk'">Emergency Service Address</th>
                <th>Status</th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="item in didList"
                v-bind:key="item.number"
                dense
              >
                <td>{{ item.number }}</td>
                <td>
                  {{getVendorNameFromIVendor(item.i_vendor)}}
                </td>
                <td>
                      <span v-if="!busy && !taskProgressPercent">Add DID to inventory
                        <span v-if="trunkId"> and assign to trunk</span>
                        <span v-if="customerId && !trunkId"> and assign to customer</span>
                      </span>
                  <span v-if="busy || taskProgressPercent">
                        <v-icon
                          color="success"
                          class="mr-2"
                          v-if="item.success"
                        >mdi-check-circle</v-icon>
                        <v-icon
                          color="warning"
                          class="mr-2"
                          v-if="item.error"
                        >mdi-alert</v-icon>
                        <v-progress-circular
                          v-if="item.busy"
                          class="mr-2"
                          :width="3"
                          :size="20"
                          color="primary"
                          indeterminate
                        ></v-progress-circular>{{ item.status }}</span>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>
    <EditTrunkIPNDAddressDialog
      v-if="trunkData != null"
      v-model="ipndDetailsDialog"
      :porta-account-data="trunkData"
      :net-sip-ipnd-data="ipndData"
    />
  </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import DIDListInput from '../../pieces/DID/DIDListInput';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import EditTrunkIPNDAddressDialog from "../Trunk/EditTrunkIPNDAddressDialog.vue";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";

export default {
  name: 'AddDIDDialogFromManualEntry',
  mixins: [apiMixin, dialogMixin, GlobalHelperMixin],
  components: {
    EditTrunkIPNDAddressDialog,
    ComplexDialog,
    DIDListInput
  },
  data: () => ({
    busy: false,
    step: 1,
    stepEnd: 6,
    ipndData:null,
    ipndDetailsDialog:false,
    dirty: false,
    didList: [],
    tasksComplete: 0,
    vnToIndex: { },
  }),
  methods: {
    reset(){
      this.didList = [];
      this.step = 1;

      let vendorDetails = this.getVenDetails( );
      let env = this.isPortaStaging ? 's':'p';
      this.vnToIndex[vendorDetails[0]['vn'][env]] = 'VX Group';
      this.vnToIndex[vendorDetails[1]['vn'][env]] = 'NetSIP';

      this.tasksComplete = 0;
    },
    updateDidList: function (value) {
      this.didList = value;
      this.dirty = true;
    },
    async createDID(did) {
      let data = {
        number: did.number,
        i_vendor: did.i_vendor,
        ipndGenericData:did.ipndGenericData,
        ipnd_id:did.ipnd_id
      };
      if(this.processTypeSwitch == 'trunk'){
        data.i_account = this.trunkId;
      }else if(this.processTypeSwitch == 'customer'){
        data.i_customer = this.customerId;
      }else if(this.processTypeSwitch != 'pool'){
        throw Error('Unrecognised processTypeSwitch ('+this.processTypeSwitch+')');
      }

      await this.$nextTick();
      let response = await this.Api.send('post','dids/create', data);
      if (response.success) {
        if(this.processTypeSwitch == 'trunk'){
          return {
            success: true,
            message: 'DID created and assigned to trunk',
          };
        }else if(this.processTypeSwitch == 'customer'){
          return { success: true, message: 'DID added to inventory' };
        }else if(this.processTypeSwitch == 'pool'){
          return { success: true, message: 'DID added to inventory' };
        }
      } else {
        return { success: false, message: response.errorDetail };
      }
    },
    createDIDs: function () {
      this.busy = true;

      const maxNumOfWorkers = 1;
      var numOfWorkers = 0;
      var taskIndex = 0;

      return new Promise((done) => {
        const handleResult = (index) => (result) => {
          this.didList[index].busy = false;
          this.didList[index].status = result.message;
          if (result.success) {
            this.didList[index].success = true;
          } else {
            this.didList[index].error = true;
          }
          numOfWorkers--;
          this.tasksComplete++;
          getNextTask();
        };
        const getNextTask = () => {
          if (
            numOfWorkers < maxNumOfWorkers &&
            taskIndex < this.didList.length
          ) {
            this.didList[taskIndex].status = 'Please wait...';
            this.didList[taskIndex].busy = true;
            this.createDID(this.didList[taskIndex])
              .then(handleResult(taskIndex))
              .catch(handleResult(taskIndex));
            taskIndex++;
            numOfWorkers++;
            getNextTask();
          } else if (
            numOfWorkers === 0 &&
            taskIndex === this.didList.length
          ) {
            taskIndex++;
            document.dispatchEvent(new CustomEvent('refreshRequested'));
            this.busy = false;
            this.dirty = false;
            done();
          }
        };
        getNextTask();
      });
    },

    next: function( ) {
      if(this.step == 2){
        if(this.taskProgressPercent > 0){
          //At this point we are on step 2, the did are processed and the user can see a list of the processing dids.
          //We remain on this page as the user can see the DID loading and then can see a success or error for each did.
          //If the taskProgressPercent > 0 we assume the process is complete.
          //Note that during the process isBusy will be true and no buttons are active.
          //So the button can only be triggered after progress has finished (EG each did was processed).
          //Now we want to reset the dialog so the user can add more did if they wish.
          this.$refs.refDidListInput.reset( );
          this.reset( );
        }else {
          //we now process the DID
          this.createDIDs();
        }
      }else {
        this.step++;
      }
    },
    back: function( ) {
      this.step--;
    },
    finish: function( ) {

    },

    getVendorNameFromIVendor:function(iVendor){
      let details = this.getVenDetails();
      let env = this.isPortaStaging()?'s':'p';

      if(details[0]['vn'][env] == iVendor){
        return 'VX Group';
      }else if(details[1]['vn'][env] == iVendor){
        return 'NetSIP';
      }

      if(env == 's'){
        alert('Unable to find vendor Tag');
      }

      return 'N/A';
    }

  },
  watch: {
    visible(value) {
      if(value){
        this.reset( );
      }
    },
    trunkId: function(val){
      this.reset();
    }
  },
  props: {
    title: {
      type: String,
      default: 'Add DIDs',
    },
    customerId: {
      default: null,
    },
    trunkData: {
      default: null,
    },
    loading:{
      type: Boolean,
      default: false,
    },
    /*
      used to trigger how the dialog will process DID
      'customer' = create and/or assign DIDs to the customer
      'trunk'=>create and/or assign DIDs to the trunk.
      'pool' => create DIDs into the pool.
     */
    processTypeSwitch:{
      type: String,
      required: true,
    },
  },
  computed: {
    isBusy: function( ){
      return this.loading || this.busy;
    },
    taskProgressPercent: function () {
      if (!this.didList.length) {
        return 0;
      }
      return (this.tasksComplete / this.didList.length) * 100;
    },
    trunkId: function( ){
      if(this.trunkData == null){
        return null;
      }else{
        return this.trunkData.i_account;
      }
    },

    dialogTitle: function(){
      return this.title + ': Step ' + this.step;
    },

    /*
    Note that
     */
    nextStepDisabled: function( ) {
      if(this.isBusy){
        return true;
      }
      if(this.step == 1 && this.didList.length==0){
        return true;
      }
      if(this.step == 2){
        //Note that we do not need check the taskProgressPercent
        //During processing the isBusy toggle will be true
        return false;
      }
      return false;
    },
    backStepDisabled: function( ) {
      if(this.isBusy){
        return true;
      }
      if(this.taskProgressPercent != 0){
        //if the taskProcessPercent is not zero we are in the presence of processing, processing is complete or there was a bug.
        return true;
      }
      return false;
    },
    nextLabel: function( ){
      if(this.step == 3){
        if(this.taskProgressPercent == 0){
          if(this.didList.length !== 1){
            return 'Add '+this.didList.length+' DIDs';
          }else{
            return 'Add DID';
          }
        }else{
          return 'Finish';
        }
      }else{
        return 'Next';
      }
    },
    endLabel: function( ){
      return 'Close';
    }
  },
  created( ){
    this.reset( );
  }
}
</script>